.phone_only {
  display: none !important;
  @include r(xs) {
    display: inherit !important; } }

.phone_hidden {
  display: inherit !important;
  @include r(xs) {
    display: none !important; } }

.Btn {
  padding: 20px 10px;
  background-color: $main-color;
  font-size: 16px;
  font-weight: 900;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  text-transform: uppercase;
  box-shadow: 0 5px 0 #9c1006;
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    box-shadow: 0 6px 29px rgba(156, 16, 6, 0.5);
    transition: all ease-in-out .25s;
    position: absolute;
    top: 0;
    left: 0; }
  &:hover {
    box-shadow: 0 3px 0 #9c1006;
    top: 2px;
    &:before {
      box-shadow: 0 6px 15px rgba(156, 16, 6, 0.5); } }
  &:active {
    box-shadow: 0 0 0 #9c1006;
    top: 5px;
    &:before {
      box-shadow: 0 0 5px rgba(156, 16, 6, 0.5); } } }

.FormSection {
  &-InputWrap {
    margin-bottom: 15px; }
  &-Input {
    width: 100%;
    height: 41px;
    padding: 0 0 0 18px;
    background-color: #ececec;
    border: none;
    border-radius: 4px; }
  &-Select {
    width: 100%;
    height: 41px;
    padding: 0 0 0 18px;
    background-color: #ececec;
    -webkit-appearance: none;
    background-image: url(/img/SelectSrrow.png);
    background-position: 95% center;
    background-repeat: no-repeat;
    border: none;
    border-radius: 4px; }
  &-Textarea {
    width: 100%;
    height: 122px;
    padding: 10px 0 0 18px;
    background-color: #ececec;
    border-radius: 4px; }
  &-InputText {
    margin-bottom: 3px;
    font-size: 16px;
    font-weight: 600;
    color: #2b2e83; }
  &-Btn {
    width: 100%;
    margin-top: 10px;
    @include r(xs) {
      font-size: 15px; } } }

.ProcessingPersonalDataText {
  font-size: 14px;
  color: #2b2e83;
  text-align: center;
  &-Link {
    color: #2b2e83;
    text-decoration: underline;
    display: inline; } }

