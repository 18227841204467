.Feedback {
  width: 100%;
  min-height: 100vh;
  padding-top: 50px;
  padding-bottom: 30px;
  background-image: url(/img/Feedback-Bg.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  @include r(xs) {
    padding-top: 20px;
    background-image: url(/img/Feedback-Bg_phone.jpg); }
  &-Title {
    margin-bottom: 5px;
    font-size: 36px;
    font-weight: 700;
    text-align: center;
    text-shadow: 0 0 5px #fff, 0 0 5px #fff, 0 0 5px #fff, 0 0 5px #fff, 0 0 5px #fff;
    @include r(xs) {
      font-size: 24px; } }
  &-SubTitle {
    margin-bottom: 80px;
    font-size: 18px;
    font-weight: 100;
    text-align: center;
    text-shadow: 1px 0 0 #fff, 0 1px 0 #fff; }
  &-Form {
    max-width: 520px;
    min-height: 520px;
    margin: 0 auto;
    padding: 20px 70px 65px;
    background-color: #ffffff;
    box-shadow: 0 11px 80px rgba(43, 46, 131, 0.45);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include r(xs) {
      padding: 20px 15px; } } }
.FormSuccess {
  text-align: center;
  &-Img {
    max-width: 100%;
    margin-bottom: 40px;
    display: inline-block;
    @include r(xs) {
      margin-bottom: 30px; } }
  &-Title {
    margin-bottom: 15px;
    font-size: 24px;
    font-weight: 700;
    color: #c90c0f;
    @include r(xs) {
      font-size: 18px; } }
  &-Text {
    font-size: 16px;
    font-weight: 600;
    color: #2b2e83;
    @include r(xs) {
      font-size: 14px; } } }

.FormSlider {
  width: 100%;
  &-Title {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 700;
    text-align: center; }
  &-Arrow {
    width: calc(100% - 50px);
    margin: -60px auto 0;
    display: block;
    @include r(xs) {
      width: 100%; }
    img {
      margin-left: 15px; }
    &.slick-disabled {
      display: none; } }
  &-ProcessingPersonalDataText {
    margin-top: 25px; }
  .slick-slide {
    padding: 0 25px;
    overflow: hidden;
    @include r(xs) {
      padding: 0; } } }
