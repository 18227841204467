//@import "../../../node_modules/bootstrap/scss/bootstrap-grid"
@import "bourbon";

$font-default: 'Open Sans', sans-serif;
$main-color: #c90c0f;

body {
  font-family: $font-default; }

.Container {
  width: 100%;
  max-width: 1280px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

